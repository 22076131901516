.App {
  
}

.App-header {
  margin: 10px;
}

.App-link {
  color: #61dafb;
}

h1 {
  font-size: 20pt;
}
h2 {
  font-size: 18pt;
}
h3 {
  font-size: 16pt;
}
h4 {
  font-size: 14pt;
}